<template>
    <div v-if="visible" class="confirm-dialog">
      <div class="confirm-dialog-content">
        <div class="confirm-dialog-message">{{ message }}</div>
        <div class="confirm-dialog-buttons">
          <button @click="handleConfirm">确认</button>
          <button @click="handleCancel">取消</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ConfirmDialog',
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      message: {
        type: String,
        default: '确认执行此操作吗？'
      }
    },
    methods: {
      handleConfirm() {
        // 触发确认事件
        this.$emit('confirm');
      },
      handleCancel() {
        // 触发取消事件
        this.$emit('cancel');
      }
    }
  };
  </script>
  
  <style scoped>
  .confirm-dialog {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .confirm-dialog-content {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
  }
  
  .confirm-dialog-message {
    margin-bottom: 20px;
  }
  
  .confirm-dialog-buttons {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .confirm-dialog-buttons button {
    margin-left: 10px;
  }
  </style>
  