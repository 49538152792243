<template>
  <div class="myhome">
    <div class="SideMenu">
      <SideMenu :menuList="menuList" />
    </div>
    <div class="MainContainer">
      <div class="MainContainer-Content">
        <router-view />
      </div>
      <div class="MainContainer-MusicPlayer">
        <MusicPlayer />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SideMenu from '@/components/SideMenu.vue'
import MusicPlayer from '@/components/MusicPlayer.vue'

export default {
  name: 'HomeView',
  components: {
    SideMenu, // 注册左侧边菜单组件
    MusicPlayer, // 注册音乐播放器组件
  },
  data() {
    return {
      // 侧边栏菜单列表
      menuList: [
        {
          id: 1,
          name: '分类歌单',
          path: '/home/hot',
          icon: 'iconfont icon-home'
        },
        {
          id: 2,
          name: '热门单曲',
          path: '/home/recommend',
          icon: 'iconfont icon-wenzhang'
        },
        {
          id: 3,
          name: 'AI智能推荐',
          path: '/home/ai',
          icon: 'iconfont icon-guanyu'
        },
        {
          id: 4,
          name: '我的收藏',
          path: '/home/collect',
          icon: 'iconfont icon-shoucang'
        }
      ],
    }
  },
}
</script>

<style scoped>
.myhome {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

/* 让 SideMenu 占据 30% 宽度 */
.SideMenu {
  flex-basis: 12%;
}

/* 让 HelloWorld 占据剩余的宽度 */
.MainContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.MainContainer-Content {
  flex: 1;
}
.MainContainer-MusicPlayer {
  background-image: linear-gradient(to right, #73f3ff, #8ebc9e);
  padding: 5px 0;
}
</style>
