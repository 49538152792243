import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css/normalize.css'// A modern alternative to CSS resets
import Toast from "vue-toastification"
import 'vue-toastification/dist/index.css'; // 引入样式
import loading from './utils/loading.js' // 引入loading

Vue.use(loading) // 全局使用loading

Vue.config.productionTip = false

const options = {
  // 配置提示框的设置
  position: 'bottom-right',
  timeout: 2500,
};
Vue.use(Toast, options);

new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this // 全局事件总线
  }
}).$mount('#app')
