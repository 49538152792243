<template>
  <div class="MusicPlayer">
    <div class="MusicPlayer-Content">
      <div class="Content-img">
        <img :src="currentItem?.picUrl || defaultSongImg" alt="music-pic" />
      </div>
      <div class="Content-left">
        <div class="MusicPlayer-Content-Up">
          <div class="MusicPlayer-Content-Up-Name">
            <span>{{ currentItem?.name || "播放列表暂无歌曲" }}</span>
            <span v-if="currentItem && currentItem.name"> - </span>
          </div>
          <div class="MusicPlayer-Content-Up-Singer">
            <span>{{ currentItem?.singer || "" }}</span>
          </div>
          <div class="MusicPlayer-Content-Btn">
            <img src="@/assets/prep.svg" @click="playPrevious">
          </div>
          <div class="MusicPlayer-Content-Btn">
            <img :src="playIconPath" @click="togglePlay">
          </div>
          <div class="MusicPlayer-Content-Btn">
            <img src="@/assets/next.svg" @click="playNext">
          </div>
        </div>
        <audio ref="audioPlayer" :src="currentItem.url"></audio>
        <div class="processBarAll">
          <div class="currentTime">{{ currentTime }}</div>
          <div class="progress-bar">
            <div class="progress" :style="{ width: progress + '%' }"></div>
          </div>
          <div class="totalTime">{{ totalTime }}</div>

          <div class="volume-controls">
            <img src="@/assets/volume.svg" style="width: 20px" />
            <input type="range" min="0" max="100" v-model="currentVolume" class="volume-range" @input="setVolume"
              style="margin-right: 10px" />
            {{ currentVolume }}
          </div>
        </div>
      </div>
      <div class="Content-right">
        <div class="Like-Btn">
          <img :src="likedUrl" @click="addOrRemoveFav">
        </div>
        <div class="Play-List" @click="showPlayList = !showPlayList">
          <img src="@/assets/24gl-playlist4.svg">
        </div>
        <transition name="slide">
          <PlayList :showPlayList="showPlayList" :items="playList" :currentSong="currentItem"
            @deleteThisSong="deleteThisSong" @closePlayList="showPlayList = !showPlayList" />
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import playIcon from "../assets/play.svg";
import pauseIcon from "../assets/pause.svg";
import liked from "../assets/like-full.svg";
import unliked from "../assets/like-empty.svg";
import PlayList from "./PlayList.vue";
import defaultSongImg from "../assets/default-song-img.svg";
import httpUtils from '@/utils/http';
export default {
  name: 'MusicPlayer',
  data() {
    return {
      showPlayList: false,
      playList: [],
      currentItem: {},
      isPlaying: false,
      progress: 0,
      currentTime: "0:00", // 初始化为'00:00'
      totalTime: "0:00", // 初始化为'00:00'
      playIconPath: playIcon, //初始化最开始为播放图标
      currentVolume: 50, // 初始化音量值
      defaultSongImg: defaultSongImg,
      musicUrl: "",
    };
  },
  components: {
    PlayList,
  },
  methods: {
    togglePlay() {
      if (this.currentItem?.id) {
        if (this.isPlaying) {
          this.audio.pause();
          this.playIconPath = playIcon;
          this.timeCounter(false);
        } else {
          this.audio.play();
          this.playIconPath = pauseIcon;
          this.timeCounter(true);
        }
        this.isPlaying = !this.isPlaying;
      } else if (this.playList.length > 0) {
        this.currentItem = this.playList[0];
        this.audio.src = this.currentItem.url;
        //this.audio.play();
        this.playIconPath = pauseIcon;
        this.isPlaying = true;
        this.timeCounter(true);
      }
      else {
        this.$toast.info("播放列表为空");
      }
    },
    playSong(index) {
      if (this.isPlaying) {
        this.audio.pause();
      }
      this.currentItem = this.playList[index];
      this.audio.src = this.currentItem.url;
      //this.audio.play();
      this.playIconPath = pauseIcon;
      this.isPlaying = true;
      this.timeCounter(true);
    },
    timeCounter(flag) {
      // flag 表示需要播放
      if (!flag) clearInterval(this.mainTimer); // 清除计时器
      else {
        this.mainTimer = setInterval(() => {
          //设置计时器
          this.currentTime = this.getCurrentTime(); // 更新当前时间
          this.totalTime = this.getTotalTime(); // 更新总时间
        }, 1000); // 每秒更新一次时间
      }
    },
    playPrevious() {
      if (this.playList.length === 0) {
        this.$toast.info("播放列表为空");
        return;
      }

      if (!this.currentItem?.id) return;

      this.audio.pause();
      this.timeCounter(false);
      const currentIndex = this.playList.findIndex(
        (item) => item.id === this.currentItem.id
      );
      const previousIndex = currentIndex === 0 ? this.playList.length - 1 : currentIndex - 1;
      this.currentItem = this.playList[previousIndex];
      this.audio.src = this.currentItem.url;
      //this.audio.play();
      this.playIconPath = pauseIcon;
      this.isPlaying = true;
      this.timeCounter(true);
    },
    playNext() {
      if (this.playList.length === 0) {
        this.$toast.info("播放列表为空");
        return;
      }

      if (!this.currentItem?.id) return;

      this.audio.pause();
      this.timeCounter(false);
      const currentIndex = this.playList.findIndex(
        (item) => item.id === this.currentItem.id
      );
      const nextIndex = currentIndex >= this.playList.length - 1 ? 0 : currentIndex + 1;
      this.currentItem = this.playList[nextIndex];
      this.audio.src = this.currentItem.url;
      //this.audio.play();
      this.playIconPath = pauseIcon;
      this.isPlaying = true;
      this.timeCounter(true);
    },
    updateProgress() {
      const audio = this.$refs.audioPlayer;
      const progress = (audio.currentTime / audio.duration) * 100;
      this.progress = Number.isNaN(progress) ? 0 : progress;
    },
    getCurrentTime() {
      if (!this.audio) {
        return "0:00";
      }
      const currentTime = this.audio.currentTime;
      const minutes = Math.floor(currentTime / 60);
      const seconds = Math.floor(currentTime % 60);
      return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
    },
    getTotalTime() {
      if (!this.audio || isNaN(this.audio.duration)) {
        return "0:00";
      }
      const totalTime = this.audio.duration;
      const minutes = Math.floor(totalTime / 60);
      const seconds = Math.floor(totalTime % 60);
      return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
    },
    setVolume() {
      this.audio.volume = this.currentVolume / 100;
    },
    getMusicUrl() {
      if (!this.currentItem?.id) return;
      let id2url = this.$httpwyy.getMusicUrl(this.currentItem.id);
      id2url.then((res) => {
        if (res && res.data && res.data.data && res.data.data.length > 0 && res.data.data[0].url) {
          this.musicUrl = res.data.data[0].url;
          this.audio.src = this.musicUrl;
        } else {
          // Handle error or invalid response
        }
      }).catch((error) => {
        console.error("获取音乐地址失败:", error);
      });
    },
    handleAudioEnded() {
      this.playNext();
    },
    handleCanPlayThrough() {
      // 音频加载完成，可以安全播放了
      this.audio.play();
    },
    async getRedirectedAudioUrl(url) { // 异步任务
      try {
        // 发送请求获取重定向后的地址
        const response = await fetch(url);
        // 获取重定向后的地址
        const redirectedUrl = response.url;
        return redirectedUrl;
      } catch (error) {
        console.error("获取重定向后的音频地址失败:", error);
        throw error; // 抛出错误以便上层处理
      }
    },
    deleteThisSong(item) {
      this.playList = this.playList.filter(song => song.id !== item.id);
      if (this.currentItem.id === item.id) {
        //如果正在播放 清空它
        this.audio.pause();
        this.audio.src = '';
        this.currentItem = {};
      }
      this.$toast.success("删除成功")
    },
    addOrRemoveFav() {
      if (this.currentItem?.id) {
        this.currentItem.liked = !this.currentItem.liked;
        if (this.currentItem.liked) {
          // 新增喜欢
          const json = { song_id: String(this.currentItem.id), song_name: this.currentItem.name, singer: this.currentItem.singer, singer_id: this.currentItem.singerId }
          const data = { favJson: JSON.stringify(json) }
          this.$http.addFav(data).then((res) => {
            if (res.data.code === 200) {
              this.$toast.success("收藏成功")
            }
            else {
              this.$toast.error(res.data.msg);
            }
          }).catch((error) => {
            this.$loading.hide();
            console.error('网络异常，添加失败:', error);
            this.$toast.error('网络异常，添加失败');
          });
        }
        else {
          httpUtils.http.post('/delFav', {
            id: this.currentItem.id //删除id
          }).then((res) => {
            if (res.data.code === 200) {
              this.$toast.success('删除成功');
            } else {
              this.$toast.error(res.data.msg);
            }
          }).catch((error) => {
            console.error('删除收藏失败:', error);
            this.$toast.error('删除收藏失败');
          });
        }
      } else {
        this.$toast.info("请先播放歌曲")
      }
    }
  },
  computed: {
    likedUrl() {
      return this.currentItem.liked ? liked : unliked;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.audio = this.$refs.audioPlayer;
      this.audio.volume = this.currentVolume / 100;
      this.audio.addEventListener("ended", this.handleAudioEnded); // 监听音乐结束切歌
      this.audio.addEventListener("timeupdate", this.updateProgress); // 监听进度条变更
      // 监听 canplaythrough 事件（缓冲音乐后播放）
      this.audio.addEventListener('canplaythrough', this.handleCanPlayThrough);
    });
    this.$bus.$on("playMusic", async (item, playNow) => {
      // 由于网易云可能存在重定向 先获取一次地址
      try {
        // 服务器数据结构和网易云不一致 修改一下数据匹配收藏页
        item = {
          ...item,
          id: item.song_id ? item.song_id : item.id,
          name: item.song_name ? item.song_name : item.name,
          singerId: item.singer_id ? item.singer_id :
            (item.ar && item.ar.length > 0 && item.ar[0].id) ? item.ar[0].id :
              (item.song && item.song.artists && item.song.artists.length > 0 && item.song.artists[0].id) ? item.song.artists[0].id :
                (item.artists && item.artists.length > 0 && item.artists[0].id) ? item.artists[0].id : "",
          singer: item.singer ? item.singer :
            (item.ar && item.ar.length > 0 && item.ar[0].name) ? item.ar[0].name :
              (item.song && item.song.artists && item.song.artists.length > 0 && item.song.artists[0].name) ? item.song.artists[0].name :
                (item.artists && item.artists.length > 0 && item.artists[0].name) ? item.artists[0].name : "",
          picUrl: item.al && item.al.picUrl ? item.al.picUrl :
            item.song && item.song.album && item.song.album.picUrl ? item.song.album.picUrl :
              ""
        }

        item.url = await this.getRedirectedAudioUrl(`https://music.163.com/song/media/outer/url?id=${item.id}.mp3`)
        //从后端获取是否收藏
        this.$http.isFav(item.id).then((res) => {
          if (res && res.data && res.data.code === 200) {
            item.liked = res.data.data > 0 ? true : false;
            let song = {
              ...item,
              url: item.url,
              liked: item.liked,
            };
            // 立即播放标志位
            if (playNow) {
              // 是否有当前播放的音乐
              if (this.currentItem?.id) {
                if (!this.playList.some(item => item.id === song.id)) {
                  // 播放列表中没有当前播放的音乐 过滤是否已经存在此歌曲

                  this.playList.push(song);
                  this.playSong(this.playList.length - 1);
                }
                else {
                  // 存在 则直接播放
                  this.playSong(this.playList.findIndex(item => item.id === song.id));
                }
                // 有播放但和要加入的音乐id一样
              } else if (this.currentItem?.id === song.id) {
                // 直接播放
                this.playSong(this.playList.findIndex(item => item.id === song.id));
              } else {
                // 未添加过 立即播放
                this.playList.push(song);

                this.playSong(this.playList.length - 1);
              }
            }
            else {
              // 不立即播放 过滤之后加入播放列表
              if (!this.playList.some(item => item.id === song.id)) {
                this.playList.push(song);
              }
            }
          } else {
            this.$toast.error("获取音乐收藏状态失败");
            console.error("获取音乐是否收藏失败:", res.data.msg);
          }
        }).catch((error) => {
          this.$toast.error("网络异常，获取音乐收藏状态失败");
          console.error("获取音乐是否收藏失败:", error);
        });
      } catch (error) {
        this.$toast.error("网络异常，获取音乐链接失败");
        console.error("获取链接失败:", error);
      }
    });
    // this.$bus.$on("playAll", async (list) => {
    //   if (Array.isArray(list)) {
    //     // 使用 Promise.all 处理异步操作
    //     await Promise.all(list.map(async (item) => {
    //       // 获取重定向后的音乐地址
    //       item.url = await this.getRedirectedAudioUrl(`https://music.163.com/song/media/outer/url?id=${item.song_id}.mp3`);
    //     }));
    //     this.playList = list.map(item => {
    //       // 把服务器数据和网易云的格式映射一下 方便组件读取
    //       return {
    //         id: item.song_id,
    //         name: item.song_name,
    //         singerId: item.singer_id,
    //         singer: item.singer,
    //         url: item.url
    //       };
    //     })
    //   } else {
    //     console.error('收到的元素不是数组:', list);
    //   }
    // });
    this.$bus.$on("playNoRedirectSong", (song) => {
      // 已经获取到重定向地址的音乐
      this.playSong(this.playList.findIndex(item => item.id === song.id));
    })
  },

  beforeDestroy() {
    if (this.audio) {
      this.audio.removeEventListener("ended", this.handleAudioEnded);
      this.audio.removeEventListener("timeupdate", this.updateProgress);
      this.audio.removeEventListener("canplaythrough", this.handleCanPlayThrough);
    }
  },
};
</script>
<style scoped>
.MusicPlayer {
  display: flex;
  justify-content: center;
}

.Content-img {
  display: flex;
}

.Content-img img {
  width: 50px;
  height: 50px;
  margin-left: 50px;
}

.processBarAll {
  display: flex;
  width: 60%;
  justify-content: center;
  align-items: center;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(1turn);
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
  /* 右侧滑入 */
}

button {
  width: 40px;
  height: 40px;
  padding: 4px;
  margin: 0 16px;
  border-radius: 50%;
  /* 设置背景颜色并透明化以显示毛玻璃效果 */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #4da3b3;
  border: none;
  opacity: 0.9;
  cursor: pointer;
}

.progress-bar {
  width: 100%;
  height: 5px;
  background-color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 5px;
}

.progress {
  height: 100%;
  background-color: #42b983;
  transition: width 0.2s linear;
}

.volume-controls {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-left: 20px;
}

.volume-range {
  width: 150px;
  margin-left: 10px;
  -webkit-appearance: none;
  /* Remove default styling */
  appearance: none;
  height: 5px;
  background-color: #ffffff;
  outline: none;
}

.volume-range::-moz-range-thumb {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #5be9bc;
  /* Customize thumb color */
  cursor: pointer;
}

.volume-range::-moz-range-track {
  height: 5px;
  background-color: #5be9bc;
  /* Default track color */
}

.volume-range::-webkit-progress-value {
  background-color: #4caf50;
  /* Customize left side color */
}

.volume-range::-moz-range-progress {
  background-color: #4caf50;
  /* Customize left side color */
}

.MusicPlayer-Content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80%;
}

.Content-left {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-basis: 70%;
}

.Content-right {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-basis: 8%;
}

.Content-right>* {
  margin: 0 5px;
}

.MusicPlayer-Content-Up {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.MusicPlayer-Content-Up {
  margin: 5px 0;
}

.MusicPlayer-Content-Up>* {
  margin-right: 10px;
}

.MusicPlayer-Content-Btn :hover {
  color: #000;
}

.MusicPlayer-Content-Btn img {
  cursor: pointer;
}

.Like-Btn img,
.Play-List img {
  cursor: pointer;
}
</style>