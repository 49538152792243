<template>
    <div class="playList" v-if="showPlayList">
        <div class="playListHeader">
            <div class="playListHeaderTitle">播放列表</div>
            <button @click="closePlayList" class="close-button">X</button>
        </div>
        <div class="playListContent">
            <div class="playListContentItem" :class="{ 'playing': currentSong && item.id === currentSong.id }" v-for="(item) in items"
                :key="item.id" @dblclick="playThisSong(item)">
                <div class="playListContentItemLeft">
                    <div class="playListContentItemLeftName">{{ item.name }} - {{ item.singer }}</div>
                </div>
                <div class="playListContentItemRight">
                    <div class="playListContentItemRightIcon" @click="addToPlay(item)">
                        <img src="@/assets/play-black.svg">
                    </div>
                    <div class="playListContentItemRightIcon" @click="deleteThisSong(item)">
                        <img src="@/assets/delete.svg">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PlayList',
    props: {
        items: {
            type: Array,
            default: () => []
        },
        currentSong: {
            type: Object,
            default: () => { }
        },
        showPlayList: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        closePlayList() {
            this.$emit('closePlayList');
        },
        addToPlay(item) {
            this.$bus.$emit('playNoRedirectSong', item); // 直接在播放列表里找到此对象 播放
        },
        deleteThisSong(item) {
            this.$emit('deleteThisSong', item);
        },
    },
}
</script>
<style scoped>
.playList {
    position: fixed;
    top: 10px;
    right: 0;
    width: 500px;
    height: 90vh;
    background-color: #fff;
    z-index: 999;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
}

.playListHeader {
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
}

.playListHeaderTitle {
    font-size: 16px;
    font-weight: bold;
}

.playListHeaderClose {
    cursor: pointer;
}

.playListContent {
    width: 100%;
    height: calc(100% - 50px);
    overflow: auto;
    box-sizing: border-box;
}

.playListContentItem {
    width: 100%;
    height: 50px;
    line-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding: 16px;
    box-sizing: border-box;
}

.playListContentItemLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 400px;
}

.playListContentItemLeftName {
    font-size: 14px;
    font-weight: bold;
}

.playListContentItemLeftSinger {
    font-size: 12px;
    color: #999;
}

.playListContentItemRight {
    display: flex;
    justify-content: center;
    align-items: center;
}

.playListContentItemRightIcon {
    display: flex;
    align-items: center;
}

.playListContentItemRightIcon img {
    width: 24px;
    line-height: 30px;
    text-align: center;
    margin-left: 10px;
    cursor: pointer;
}

.close-button {
    position: absolute;
    right: 10px;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border: 1px solid #7df0bc;
    background-color: #fff;
    opacity: 0.8;
    cursor: pointer;
    transition: all 0.3s;
    text-align: center;
    font-size: 10px;
}

.close-button:hover {
    background-color: #40f2a2;
}

.playListContentItem.playing {
    background-color: #f0f0f0; /* 设置正在播放歌曲的背景颜色 */
}
</style>