<template>
    <div class="chat-container">
        <div v-if="messages.length === 0" class="message">
            你今天想听点什么呢？试着和我说：我想听流行音乐。
        </div>
        <div class="chat-messages" v-else ref="chatMessages">
            <div v-for="(message, index) in messages" :key="index">
                <div v-if="message.type === 1" class="right-msg">
                    <div class="user-message">
                        {{ message.msg }}
                    </div>
                </div>
                <div v-else class="left-msg">
                    <div class="server-message" v-html="message.msg"></div>
                </div>
            </div>
            <div v-if="flowMessage !== ''" class="server-message">
                {{ flowMessage }}</div>
        </div>
        <div class="chat-input">
            <input type="text" v-model="inputMessage" :disabled="!canSendMessage" @keyup.enter="sendMessage"
                placeholder="请输入消息...">
            <button @click="sendMessage" :disabled="!canSendMessage">发送</button>
        </div>
        <transition name="slide">
            <div v-if="showPlayList" class="PlayListDetail">
                <button @click="togglePlayList" class="close-button">X</button>
                <div class="playListName">搜索结果</div>
                <div v-if="playListDetail.length === 0">加载中。。。</div>
                <div v-else style="overflow: auto;">
                    <!-- 在这里展示歌曲清单 -->
                    <div class="SongItem" v-for="(song, index) in playListDetail" :title="song.name" :key="index"
                        @click="addToPlay(song)">
                        {{ index + 1 }} . {{ song.name }} - {{ song.artists[0].name }}
                        <div class="btn-container">
                            <span><img src="@/assets/play-black.svg" @click="addToPlay(song)" title="立即播放"></span>
                            <span><img src="@/assets/add-favorite.svg" @click="addFav(song)" title="添加到我的收藏"></span>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            inputMessage: '',
            flowMessage: '',
            messages: [],
            connectionId: localStorage.getItem('yltoken'),
            canSendMessage: true, //尚未回传完毕之前不能再次提交
            wsConn: null,
            searchKeyWords: '', // 缓存搜索歌曲名 避免重复搜索
            showPlayList: false, // 搜索结果框
            loading: false, // 加载状态
            playListDetail: [], // 搜索结果
        };
    },
    watch: {
        // 监听 flowMessage 的高度变化
        flowMessage() {
            // 当变化时，滚动到底部
            this.scrollToBottom();
        }
    },
    methods: {
        sendMessage() {
            // 发送ws消息 请求AI返回流文本
            if (this.inputMessage.trim() !== '') {
                this.messages.push({ type: 1, title: 'User', msg: this.inputMessage });
                this.canSendMessage = false; // 禁用输入框
                this.wsConn.send(this.inputMessage)
                this.inputMessage = '';
            }
        },
        formatMessage() {
            //把流文本里的歌名替换成链接 换行换成<br> 然后保存到对话栈

            for (var i = 0; i < this.flowMessage.length; i++) {
                let msg = this.flowMessage;

                msg = msg.replace(/\n/g, '<br>');
                // msg = msg.replace(/"([^"]+)"/g, '<a href="http://localhost:8080/#/home/ai?keywords=$1">$1</a>'); //本地演示
                // msg = msg.replace(/《([^》]+)》/g, '<a href="http://localhost:8080/#/home/ai?keywords=$1">$1</a>');

                msg = msg.replace(/"([^"]+)"/g, '<a href="https://ylmusic.top/#/home/ai?keywords=$1">$1</a>');
                msg = msg.replace(/《([^》]+)》/g, '<a href="http://ylmusic.top/#/home/ai?keywords=$1">$1</a>');

                this.flowMessage = msg;

                const data = { type: 0, title: 'Server', msg: this.flowMessage };
                this.messages.push(data);
                this.flowMessage = '';
                this.canSendMessage = true;
                this.scrollToBottom();
            }
        },
        // 滚动到底部
        scrollToBottom() {
            // 在 nextTick 中滚动到底部，确保内容已经更新完毕
            this.$nextTick(() => {
                // 使用 $refs 获取 chat-messages 元素，并将滚动条滚动到底部
                this.$refs.chatMessages.scrollTop = this.$refs.chatMessages.scrollHeight;
            });
        },
        addToPlay(item) {
            this.$bus.$emit('playMusic', item, true); // 全局总线事件添加到播放列表并立即播放
            this.$toast.success("已添加到播放列表");
        },
        addFav(item) {
            const json = { song_id: String(item.id), song_name: item.name, singer: item.ar? item.ar[0].name : item.artists[0].name, singer_id: item.ar? item.ar[0].id : item.artists[0].id}
            const data = { favJson: JSON.stringify(json) }
            this.$http.addFav(data).then((res) => {
                if (res.data.code === 200) {
                    this.$toast.success("收藏成功")
                }
                else {
                    this.$toast.error(res.data.msg);
                }
            }).catch((error) => {
                this.$loading.hide();
                console.error('网络异常，添加失败:', error);
                this.$toast.error('网络异常，添加失败');
            });
        },
        togglePlayList() {
            this.showPlayList = !this.showPlayList;
            if (!this.showPlayList) {
                // 如果关闭清单，设置一个延时动画 清空列表
                setTimeout(() => {
                    this.playListDetail = [];
                    this.loading = true; // 重新设置加载状态
                }, 300);
            }
        },
    },
    mounted() {
        //初始化页面之后 创建ws链接 yltoken作为链接票据
        this.wsConn = new WebSocket("wss://ylmusic.top:6789/websocket/" + this.connectionId);

        this.wsConn.onopen = () => {
            console.log("WebSocket连接已建立");
        };

        this.wsConn.onmessage = (event) => {
            if (event.data != "完成") {
                this.flowMessage = event.data;
            } else {
                this.formatMessage();
            }
        };

        this.wsConn.onerror = (event) => {
            console.error(event);
            // 在这里处理连接错误的情况
        };

        this.wsConn.onclose = (event) => {
            console.log("WebSocket连接已关闭" + event);
        };
    },
    beforeDestroy() {
        if (this.wsConn) {
            this.wsConn.close();
        }
    },
    beforeRouteUpdate(to) {
        // 路由监视  当点击搜索结果中歌名时 会触发路由更新 展示搜索框并搜索
        const keywords = to.query.keywords;
        if (this.searchKeyWords === keywords)
            return;
        this.searchKeyWords = keywords;
        this.showPlayList = true;
        this.loading = true;
        this.$httpwyy.searchMusic(keywords).then((res) => {
            if (res.data.code === 200) {
                this.playListDetail = res.data.result.songs;
                this.loading = false;
            } else {
                this.$toast.error(res.data.msg);
                this.loading = false;
            }
        }).catch((error) => {
            this.loading = false;
            console.error('搜索失败:', error);
            this.$toast.error('搜索失败');
        });
    }
};
</script>
  
<style>
.chat-container {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 85vh;
    overflow: hidden;
}

.chat-messages {
    margin-bottom: 10px;
    overflow: auto;
    max-height: 80vh;
}

.message {
    margin-top: 80px;
    font-size: 30px;
    font-weight: 400;
}

.left-msg,
.right-msg {
    display: flex;
    margin: 12px;
}

.left-msg {
    justify-content: start;
}

.right-msg {
    justify-content: end;
}

.server-message,
.user-message {
    min-width: 50px;
    max-width: 500px;
    border-radius: 5px;
    padding: 10px;
}

.user-message {
    background-color: #e0f7fa;
    margin-right: 20px;
}

.server-message {
    background-color: #c8e6c9;
    margin-left: 20px;
}

.chat-input {
    width: 100%;
    background-color: #fff;
    padding: 10px;
    box-sizing: border-box;
}

.chat-input input {
    width: calc(50% - 80px);
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-right: 10px;
}

.chat-input button {
    padding: 10px 16px;
    border-radius: 5px;
    background-color: #2196F3;
    color: #fff;
    border: none;
    cursor: pointer;
}

.slide-enter-active,
.slide-leave-active {
    transition: transform 0.3s;
}

.slide-enter,
.slide-leave-to {
    transform: translateY(100%);
    /* 下方滑入 */
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 1px solid #7df0bc;
    background-color: #fff;
    opacity: 0.8;
    cursor: pointer;
    transition: all 0.3s;
}

.playListName {
    font-size: 24px;
    font-weight: bolder;
    padding: 10px;

}

.PlayListDetail {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: 60vh;
    background-image: linear-gradient(to right, #bae8d2, #f2fcc4);
    z-index: 999;
    padding: 16px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
}

.SongItem {
    border-radius: 5px;
    box-shadow: 6px 12px 15px rgba(0, 0, 0, 0.1);
    line-height: 30px;
    box-sizing: border-box;
    cursor: pointer;
    margin: 24px 16px;
    padding: 12px 40px;
    text-align: left;
    display: flex;
    justify-content: space-between;
}

.btn-container>* {
    margin-right: 10px;
}

.btn-container img {
    width: 32px;
    height: 32px;
}</style>
  