<template>
    <div class="collect-view">
        <confirm-dialog :visible="dialogVisible" @confirm="handleConfirm" @cancel="handleCancel" />
        <div class="collect-title">
            <h2>我的收藏</h2>
        </div>
        <div class="collect-list">
            <div class="collect-item">
                <div class="collect-item-title">歌曲名</div>
                <div class="collect-item-content">歌手</div>
                <div class="collect-item-action">操作</div>
            </div>
            <div class="collect-item" v-for="item in items" :key="item.id">
                <div class="collect-item-title">{{ item.song_name }}</div>
                <div class="collect-item-content">{{ item.singer }}</div>
                <div class="collect-item-action">
                    <span @click="addToPlay(item)" title="播放"><img src="@/assets/play-black.svg"></span>
                    <span @click="delFav(item.song_id)" title="从收藏中删除"><img src="@/assets/delete.svg"></span>
                </div>
            </div>
        </div>
        <div class="pagination">
            <span class="page" v-if="currentPage > 1" @click="prevPage()">上一页</span>
            <span class="page" v-for="pageNumber in visiblePageNumbers" :class="{ active: currentPage === pageNumber }"
                :key="pageNumber" @click="changePage(pageNumber)">
                {{ pageNumber }}
            </span>
            <span class="page" v-if="currentPage < totalPages - 1" @click="nextPage()">下一页</span>
            <span class="page">前往第<input type="text" v-model="inputPage" @keyup.enter="jumpPage" />页</span>
            <span class="totalsize"> 共{{ totalSize }}条记录 </span>
        </div>
    </div>
</template>

<script>
import ConfirmDialog from '@/components/ComfirmDialog.vue';
import httpUtils from '@/utils/http';
export default {
    name: 'CollectView',
    data() {
        return {
            currentPage: 1, // 默认第一页
            inputPage: "",
            pageSize: 12,
            totalSize: 0, // 默认总数
            items: [],
            dialogVisible: false, //删除收藏确认提示框
            itemIdToDelete: 0, // 计划删除的id
        }
    },
    components: {
        ConfirmDialog
    },
    methods: {
        getFavList() {
            this.$http.get('/getFavList/' + this.currentPage).then((res) => {
                if (res.data.code === 200) {
                    this.items = res.data.data.list;
                    this.totalSize = res.data.data.count;
                } else {
                    this.$toast.error(res.data.msg);
                }
            }).catch((error) => {
                console.error('获取收藏列表失败:', error);
                this.$toast.error('获取收藏列表失败');
            });
        },
        delFav(id) {
            // 展示确认框 缓存要删除的id
            this.dialogVisible = true;
            this.itemIdToDelete = id;
        },
        handleConfirm() {
            // 确认事件处理逻辑
            this.dialogVisible = false; // 关闭对话框
            httpUtils.http.post('/delFav', {
                id: this.itemIdToDelete
            }).then((res) => {
                if (res.data.code === 200) {
                    this.$toast.success('删除成功');
                    this.getFavList();
                } else {
                    this.$toast.error(res.data.msg);
                }
            }).catch((error) => {
                console.error('删除收藏失败:', error);
                this.$toast.error('删除收藏失败');
            });
        },
        handleCancel() {
            // 取消事件处理逻辑
            console.log('取消操作');
            this.dialogVisible = false; // 关闭对话框
        },
        prevPage() {
            this.currentPage -= 1;
        },
        nextPage() {
            this.currentPage += 1;
        },
        changePage(pageNumber) {
            this.currentPage = pageNumber;
        },
        jumpPage() {
            if (/^\d+$/.test(this.inputPage)) {
                const pageNumber = Number(this.inputPage);
                if (pageNumber >= 1 && pageNumber <= this.totalPages) {
                    this.currentPage = pageNumber;
                } else {
                    this.inputPage = "";
                }
            } else {
                this.inputPage = "";
            }
        },
        addToPlay(item) {
            this.$bus.$emit('playMusic', item, true); // 全局总线事件添加到播放列表并立即播放
            this.$toast.success("已添加到播放列表");
        },
    },
    computed: {
        totalPages() {
            if (this.totalSize === 0) return 1;
            if (this.totalSize % this.pageSize === 0)
                return this.totalSize / this.pageSize;
            else return Math.floor(this.totalSize / this.pageSize) + 1;
        }, visiblePageNumbers() {
            let fromNumber = this.currentPage - 2;
            if (fromNumber <= 1) fromNumber = 1;
            let toNumber = fromNumber + 4;
            if (toNumber > this.totalPages) toNumber = this.totalPages;
            const pageNumbers = [];
            for (let i = fromNumber; i <= toNumber; i++) {
                pageNumbers.push(i);
            }
            if (fromNumber != 1) pageNumbers.unshift(1);
            if (toNumber != this.totalPages) pageNumbers.push(this.totalPages);
            return pageNumbers;
        },
    },
    watch: {
        currentPage() {
            this.getFavList();
        }
    },
    mounted() {
        this.getFavList();
    },
}
</script>
<style scoped>
.collect-view {
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.collect-list {
    width: 60%;
    height: 70vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.collect-title {
    border-bottom: 1px solid #ddd;
    justify-content: flex-start;
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 100%;
}

.pagination {
    margin-top: 30px;
}

.collect-item {
    display: flex;
    justify-content: space-between;
    padding: 16px 20px;
    align-items: center;
}

.collect-item-title {
    text-align: left;
    flex-basis: 50%;
    /* 占50% */
}

.collect-item-content {
    text-align: left;
    flex-grow: 3;
    /* 占30% */
}

.collect-item-action {
    text-align: right;
}

.collect-item-action>* {
    margin: 0 10px;
    cursor: pointer;
}

.collect-item-action>* img {
    width: 18px;
    height: 18px;
}

.collect-item:nth-child(odd) {
    background-color: #eefaf5;
}

.collect-item:nth-child(even) {
    background-color: #ffffff;

}

.page {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 4px;
    font-size: 14px;
    height: 28px;
    line-height: 1;
    padding: 0 12px;
    border-radius: 2px;
    background-color: #fff;
    color: #1f2d3d;
    cursor: pointer;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid #dcdfe6;
}

.page:hover {
    background-color: #f5f7fa;
}

.page.active {
    background-color: #409eff;
    color: #fff;
    border-color: #409eff;
}

.page input {
    width: 40px;
    margin: 5px;
    text-align: center;
    border: 1px solid #dcdfe6;
    border-radius: 2px;
    background-color: #fff;
    color: #1f2d3d;
    outline: none;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
</style>

