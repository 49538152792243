<template>
    <div class="side-menu">
        <confirm-dialog :message="message" :visible="dialogVisible" @confirm="handleConfirm" @cancel="handleCancel" />
        <router-link v-for="(item, index) in menuList" :to="item.path" :key="item.id" @click="selectMenu(index)">
            <div :class="{ 'selected': selectedIndex === index }" class="side-menu-item" @click="selectMenu(index)">
                <span>{{
                    item.name }}</span>
            </div>
        </router-link>
        <div class="side-menu-bottom">
            <div class="user-name">{{ userName }}</div>
            <img src="@/assets/power.svg" @click="showtips" style="cursor: pointer;">
        </div>
    </div>
</template>

<script>
import ConfirmDialog from './ComfirmDialog.vue';
import httpUtils from '@/utils/http';
export default {
    name: 'SideMenu',
    props: {
        menuList: {
            type: Array,
            default: () => []
        },
    },
    components: {
        ConfirmDialog
    },
    data() {
        return {
            message: "确认退出吗？", // 确认提示框内容
            selectedIndex: 0,
            dialogVisible: false, //删除收藏确认提示框
            userName: localStorage.getItem('nickname') || ''
        }
    },
    methods: {
        selectMenu(index) {
            this.selectedIndex = index;
        },
        showtips() {
            // 展示确认框 确认退出
            this.dialogVisible = true;
        },
        handleConfirm() {
            // 确认事件处理逻辑
            this.dialogVisible = false; // 关闭对话框
            httpUtils.http.post('/logOut').then((res) => {
                if (res.data.code === 200) {
                    this.$toast.success('已退出登录');
                    this.$router.push({ name: 'login' });
                } else {
                    this.$toast.error(res.data.msg);
                    this.$router.push({ name: 'login' });
                }
            }).catch((error) => {
                console.error('网络异常:', error);
                this.$toast.error('网络异常');
                this.$router.push({ name: 'login' });
            });
        },
        handleCancel() {
            // 取消事件处理逻辑
            console.log('取消操作');
            this.dialogVisible = false; // 关闭对话框
        },
    },
    mounted() {
        // 页面加载后自动选择第一项
        if (this.menuList.length > 0) {
            // 获取当前路由路径
            const currentRoute = this.$route.path;
            // 获取第一项的路径
            const firstItemPath = this.menuList[0].path;
            // 如果当前路由不是第一项的路径，则执行路由跳转操作
            if (currentRoute !== firstItemPath) {
                this.$router.push(firstItemPath);
            }
        }
    }
}
</script>
<style scoped>
.side-menu {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #e0e0e0;
    position: relative;
    padding: 20px;
    /* 增加容器内边距 */
}

.side-menu-item {
    width: 100%;
    line-height: 50px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.3s;
    margin: 10px 0;
    /* 添加上下间距 */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    /* 外部阴影效果 */
    border-radius: 5px;
}

.side-menu .selected {
    background-image: linear-gradient(to right, #ccffe6, #99cfff);
}

.side-menu-item span {
    color: #000000;
}

.side-menu-item:hover {
    background-image: linear-gradient(to right, #ccffe6, #99cfff);
    /* 悬浮时的背景渐变色 */
}

.side-menu-item span:active,
.side-menu-item span:focus {
    outline: none;
    /* 移除点击或聚焦时的默认边框 */
}

a {
    text-decoration: none;
}

a:active {
    color: #42b983;
}

.side-menu-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 16px;
    height: 76px;
    box-sizing: border-box;
    border-top: 1px solid #e0e0e0;
    background-image: linear-gradient(to right, #ccffe6, #99cfff);
}

.side-menu-bottom img {
    height: 20px;
}
</style>
