<template>
    <div v-if="show" class="lds-spinner">
        <div class="spinner">
            <div class="double-bounce1"></div>
            <div class="double-bounce2"></div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'GLoading',
    props: {
        show: Boolean
    },
    data() {
        return {
        }
    }
}
</script>
<style scoped>
.lds-spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.spinner {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
}

.spinner .double-bounce1,
.spinner .double-bounce2 {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #51ffcb;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: bounce 2.0s infinite ease-in-out;
}

.spinner .double-bounce2 {
    animation-delay: -1.0s;
}

@keyframes bounce {

    0%,
    100% {
        transform: scale(0.0);
    }

    50% {
        transform: scale(1.0);
    }
}</style>