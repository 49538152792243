<template>
    <div class="hotPlayList">
        <div class="searchPanel">
            <input type="text" placeholder="搜索" class="searchInput" v-model="searchKeyWords">
            <img src="@/assets/search.svg" alt="Search Icon" class="searchIcon" @click="searchSong">
        </div>
        <div class="hotPlayListTitle">
            <div class="hotPlayListTitleLeft">
                <span class="hotPlayListTitleLeftIcon iconfont icon-wenzhang"></span>
                <span class="hotPlayListTitleLeftText">歌单分类</span>
            </div>
        </div>
        <div class="hotPlayListContent">
            <div class="hotPlayListContentItem" v-for="(item, index) in category" :key="index"
                @click="changeCategory(item.name)" :class="{ active: activeItem === item.name }">
                <div class="hotPlayListContentItemText">
                    {{ item.name }}
                </div>
            </div>
        </div>
        <div class="hotPlayListTitle">
            <div class="hotPlayListTitleLeft">
                <span class="hotPlayListTitleLeftIcon iconfont icon-wenzhang"></span>
                <span class="hotPlayListTitleLeftText">热门歌单</span>
            </div>
        </div>
        <div class="hotPlayListContent">
            <div class="hotPlayListContentItem higher" v-for="(item, index) in items" :key="index"
                @click="jump2playList(item)">
                <div class="hotPlayListContentItemImg">
                    <img :src="item.coverImgUrl" alt="">
                </div>
                <div class="hotPlayListContentItemText">
                    <span class="hotPlayListContentItemTextTitle">{{ item.name }}</span>
                </div>
            </div>
        </div>
        <transition name="slide">
            <div v-if="showPlayList" class="PlayListDetail">
                <button @click="togglePlayList" class="close-button">X</button>
                <div class="playListName">{{ selectedPlayList.name }}</div>
                <div v-if="playListDetail.length === 0">加载中。。。</div>
                <div v-else style="overflow: auto;">
                    <!-- 在这里展示歌曲清单 -->
                    <div class="SongItem" v-for="(song, index) in playListDetail" :title="song.name" :key="index"
                        @dblclick="addToPlay(song)">
                        {{ index + 1 }} . {{ song.name }} - {{ song.ar ? song.ar[0].name : song.artists[0].name }}
                        <div class="btn-container">
                            <span><img src="@/assets/play-black.svg" @click="addToPlay(song)" title="立即播放"></span>
                            <span><img src="@/assets/add-favorite.svg" @click="addFav(song)" title="添加到我的收藏"></span>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
export default {
    name: 'HotPlayList',
    data() {
        return {
            activeItem: null,
            showPlayList: false,
            loading: false, // 加载状态
            category: [], //推荐歌单类型
            items: [], // 推荐歌单,选择歌单类型之后会更换
            playListDetail: [],
            selectedPlayList: {},
            searchKeyWords: '',
        }
    },
    methods: {
        changeCategory(name) {
            this.$loading.show();
            this.activeItem = name;
            this.$httpwyy.get('/top/playlist?limit=30&cat=' + name).then((res) => {
                this.$loading.hide();
                if (res.data.code === 200) {
                    this.items = res.data.playlists;
                } else {
                    this.$toast.error(res.data.msg);
                }
            }).catch((error) => {
                this.$loading.hide();
                console.error('获取推荐歌单内容失败:', error);
                this.$toast.error('获取推荐歌单内容失败');
            });
        },
        jump2playList(item) {
            this.selectedPlayList = item;
            this.showPlayList = true;
            this.$loading.show();
            this.$httpwyy.get('/playlist/track/all?id=' + item.id + '&limit=30').then((res) => {
                this.$loading.hide();
                if (res.data.code === 200) {
                    this.playListDetail = res.data.songs;
                } else {
                    this.$toast.error(res.data.msg);
                }
            }).catch((error) => {
                this.$loading.hide();
                console.error('获取推荐歌单内容失败:', error);
                this.$toast.error('获取推荐歌单内容失败');
            });
        },
        // 获取推荐歌单类型
        getCategory() {
            this.$loading.show();
            this.$httpwyy.get('/playlist/hot').then((res) => {
                this.$loading.hide();
                if (res.data.code === 200) {
                    res.data.tags.forEach((item) => {
                        this.category.push(item)
                    });
                } else {
                    this.$toast.error(res.data.msg);
                }
            }).catch((error) => {
                this.$loading.hide();
                console.error('获取推荐歌单类型失败:', error);
                this.$toast.error('获取推荐歌单类型失败');
            });
        },
        getHotPlayList(category) {
            this.$loading.show();
            this.$httpwyy.get('/top/playlist?cat=' + category).then((res) => {
                this.$loading.hide();
                if (res.data.code === 200) {
                    this.items = res.data.playlists;
                } else {
                    this.$toast.error(res.data.msg);
                }
            }).catch((error) => {
                this.$loading.hide();
                console.error('获取推荐歌单失败:', error);
                this.$toast.error('获取推荐歌单失败');
            });
        },
        togglePlayList() {
            this.showPlayList = !this.showPlayList;
            if (!this.showPlayList) {
                // 如果关闭清单，设置一个延时动画 清空列表
                setTimeout(() => {
                    this.playListDetail = [];
                    this.loading = true; // 重新设置加载状态
                }, 300);
            }
        },
        addToPlay(item) {
            this.$bus.$emit('playMusic', item, true); // 全局总线事件添加到播放列表并立即播放
            this.$toast.success("已添加到播放列表");
            console.log(item.id)
        },
        addFav(item) {
            const json = { song_id: String(item.id), song_name: item.name, singer: item.ar? item.ar[0].name : item.artists[0].name, singer_id: item.ar? item.ar[0].id : item.artists[0].id}
            const data = { favJson: JSON.stringify(json) }
            this.$http.addFav(data).then((res) => {
                if (res.data.code === 200) {
                    this.$toast.success("添加成功")
                }
                else {
                    this.$toast.error(res.data.msg);
                }
            }).catch((error) => {
                this.$loading.hide();
                console.error('网络异常，添加失败:', error);
                this.$toast.error('网络异常，添加失败');
            });
        },
        searchSong() {
            if (this.searchKeyWords !== '') {
                this.selectedPlayList = { name: '搜索音乐' };
                this.showPlayList = true
                this.$httpwyy.searchMusic(this.searchKeyWords).then((res) => {
                    if (res.data.code === 200) {
                        this.playListDetail = res.data.result.songs;
                        this.loading = false;
                    } else {
                        this.$toast.error(res.data.msg);
                        this.loading = false;
                    }
                }).catch((error) => {
                    this.loading = false;
                    console.error('搜索失败:', error);
                    this.$toast.error('搜索失败');
                });
            }
        }
    },
    mounted() {
        this.getCategory();
        this.getHotPlayList();
    }
}
</script>
<style scoped>
.hotPlayList {
    width: 100%;
    height: 90vh;
    background-color: #fff;
    padding: 20px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.hotPlayListTitle {
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hotPlayListTitleLeft {
    display: flex;
    align-items: center;
}

.hotPlayListTitleLeftIcon {
    font-size: 20px;
    color: #42b983;
}

.hotPlayListTitleLeftText {
    font-size: 24px;
    font-weight: bold;
    margin-left: 10px;
}

.hotPlayListTitleRight {
    display: flex;
    align-items: center;
}

.hotPlayListTitleRightText {
    font-size: 14px;
    color: #42b983;
}

.hotPlayListTitleRightIcon {
    font-size: 14px;
    margin-left: 10px;
}

.hotPlayListContent {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    box-sizing: border-box;
    justify-content: flex-start;
    /* 左对齐 */
    align-items: flex-start;
    /* 顶部对齐 */
}

.higher {
    height: 150px;
}

.hotPlayListContentItem {
    flex: 1 1 20%;
    /* 每个元素自动分配宽度，使得每行最多容纳五个元素 */
    margin: 10px;
    /* 设置元素之间的间距 */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s;
    /* 添加背景色过渡效果 */
    overflow: hidden;
    /* 隐藏文本溢出部分 */
    padding: 0 32px;
}

.hotPlayListContentItem:hover,
.hotPlayListContentItem.active {
    background-image: linear-gradient(to right, #ccffe6, #99cfff);
    /* 鼠标悬停时的背景渐变色 */
}

.hotPlayListContentItem .content {
    padding: 10px;
    /* 调整内边距 */
    text-align: center;
    /* 居中文本 */
}

.hotPlayListContentItemText {
    height: 60px;
    line-height: 60px;
    text-align: center;
}

.hotPlayListContentItemImg {
    height: 90px;
    line-height: 60px;
    text-align: center;
}

.hotPlayListContentItemImg img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.hotPlayListContentItemText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 16px;
}

.hotPlayListContentItemTextTitle {
    font-size: 14px;
    font-weight: bold;
}

.hotPlayListContentItemTextAuthor {
    font-size: 12px;
    color: #999;
}

.playListName {
    font-size: 24px;
    font-weight: bolder;
    padding: 10px;

}

.PlayListDetail {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: 60vh;
    background-image: linear-gradient(to right, #bae8d2, #f2fcc4);
    z-index: 999;
    padding: 16px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
}


.SongItem {
    border-radius: 5px;
    box-shadow: 6px 12px 15px rgba(0, 0, 0, 0.1);
    line-height: 30px;
    box-sizing: border-box;
    cursor: pointer;
    margin: 24px 16px;
    padding: 12px 40px;
    text-align: left;
    display: flex;
    justify-content: space-between;
}

.SongItem:hover {
    background-image: linear-gradient(to right, #a3edc9, #edfea1);
}

.slide-enter-active,
.slide-leave-active {
    transition: transform 0.3s;
}

.slide-enter,
.slide-leave-to {
    transform: translateY(100%);
    /* 下方滑入 */
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 1px solid #7df0bc;
    background-color: #fff;
    opacity: 0.8;
    cursor: pointer;
    transition: all 0.3s;
}

.close-button:hover {
    background-color: #40f2a2;
}

.btn-container>* {
    margin-right: 10px;
}

.searchPanel {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 100%;
    margin-bottom: 16px;
}

.searchInput {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 300px;
    /* 可根据需要调整宽度 */
}

.searchIcon {
    margin-left: 8px;
    cursor: pointer;
    height: 30px;
}
</style>